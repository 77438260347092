import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{
		// 主入口页面

		path: '',
		loadChildren: () => import('./component/component.module').then(m => m.ComponentModule),
		// canActivate: [LoginGuard]

	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
