
export default {
  gqlHost: 'https://www.edu.mongol.pub/api',
  iconUrl: '//at.alicdn.com/t/font_1783076_ybtaryelech.js',
  ////at.alicdn.com/t/font_1279512_pt0f0br2an.css
  // 高德地图key值
  key: '03b0f9acdd37a3e4ac6843bf72028406',
  language: 'mn', //zh,en,mn,
  baseUrl: '/api/',
  baseUrlOne: '/',
  graphQlUrl: '/api/base/graphql', // GraphQl url地址
  ImageUrl: 'https://api.dev.campus.orhonedu.com/', // 图片上传地址
  weatherUrl: "/weather/",
  Affix: true, // demo 是否显示
  // 路由白名单
  white_path: ['/pages/notice-content'],
};
