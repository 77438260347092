import { NgModule } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ApolloLink, concat, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import Config from './config/gqlhost';
import { BrowserModule } from '@angular/platform-browser';
import { HttpLinkModule } from 'apollo-angular-link-http';


@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpLinkModule
  ]
})
export class GraphQLModule {
  constructor(apollo: Apollo, httpLink: HttpLink) {
    const authMiddleware = new ApolloLink((operation, forward) => {
      let headers = {};
      const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsic3ByaW5nLWJvb3QtYXBwbGljYXRpb24iXSwidXNlcl90eXBlIjoic2Nob29sX2FkbWluIiwic2Nob29sX2lkIjo1NjksInVzZXJfbmFtZSI6ImFsa2VxcXRzZGV6eCIsInNjb3BlIjpbInNtYXJ0Y2FtcHVzLWJhc2UiXSwiZXhwIjoxNjk3NzQ3NDIyLCJ1c2VyaWQiOjQ4MDUxLCJhdXRob3JpdGllcyI6WyJST0xFX1VTRVIiXSwianRpIjoiOWEwYzljMTEtMzI1ZC00OGIyLWFhNzMtYjgxNWM4Njc0ZmQ2IiwiY2xpZW50X2lkIjoiYmFzZSIsInVzZXJuYW1lIjoiYWxrZXFxdHNkZXp4In0.Q2N9qnEKa-8ljgYyRXTvnvC4T0wWc5W5Q-LTlF7WCuc";
      if (token) {
        headers = new HttpHeaders().set('token', token);
      }
      operation.setContext({
        headers,
      });
      return forward(operation);
    });

    /**
     * 默认接口
     */
    const baseDefault = httpLink.create({ uri: Config.hostGqlDefault });
    apollo.createDefault({
      link: concat(authMiddleware, baseDefault),
      cache: new InMemoryCache({
        addTypename: false,
      }),
    });

    /**
     * 扩展接口
     */
    Config.hostGql.map(el => {
      const httpL = httpLink.create({ uri: el.url });
      apollo.createNamed(el.name, {
        link: concat(authMiddleware, httpL),
        cache: new InMemoryCache({
          addTypename: false,
        }),
      });
    });
  }
}
