import Config from './index';

export default {
  hostGqlDefault: `${Config.baseUrl}base/graphql`,
  hostGql: [
    {
      url: 'https://edu.mongol.pub/api/region/regiongql',
      name: 'region',
    },
    {
      url: 'https://www.edu.mongol.pub/api/attendence/graphql',
      name: 'attendance',
    },
    {
      url: 'https://www.edu.mongol.pub/api/teacher-profile/teachers-profile-gql',
      name: 'teacherprofile',
    },
    {
      url: 'https://www.edu.mongol.pub/api/material/graphql',
      name: 'material',
    },
    {
      url: 'https://www.edu.mongol.pub/api/examination/graphql',
      name: 'examination',
    },
    {
      url: 'https://www.edu.mongol.pub/api/moral/graphql',
      name: 'moral',
    },
    {
      url: 'https://www.edu.mongol.pub/api/official-document/graphql',
      name: 'officialdocument',
    },
    {
      url: 'https://www.edu.mongol.pub/api/canteen/graphql',
      name: 'canteen',
    },
    {
      url: 'https://www.edu.mongol.pub//api/epidemic/graphql',
      name: 'epidemic',
    },
    {
      url: 'https://www.edu.mongol.pub/api/studentattendence/graphql',
      name: 'studentattendence',
    },
    {
      url: 'https://www.edu.mongol.pub/api/preparation/graphql',
      name: 'preparation',
    },
    {
      url: 'https://www.edu.mongol.pub/api/arrangecourse/graphql',
      name: 'arrangecourse',
    }
  ],
};
